<template>
  <div class="page">
    <div v-if="getModuleConfig('intro_page.form_title')" class="page__title">
    {{ getModuleConfig("intro_page.form_title") }}
    </div>
    <div v-if="getModuleConfig('intro_page.form_desc')" class="page__desc">
    {{ getModuleConfig("intro_page.form_desc") }}
    </div>

    <div class="page__content" v-if="contentData" v-html="contentData"></div>
    <img
      v-for="image in contentImages"
      :src="image.image_url"
      :key="image.order"
      class="w-100"
    />

    <div class="page__button s-space-y-4">
      <MemberButton
        :button-text="getModuleConfig('intro_page.button_text')"
        :button-url="getModuleConfig('intro_page.button_url')"
        :type="getModuleConfig('intro_page.button_action')"
      ></MemberButton>
    </div>
  </div>
</template>

<script>
import registerMixin from "@/mixins/liff/register";
import MemberButton from "@/components/Page/Liff/Shared/MemberButton.vue";

export default {
  mixins: [registerMixin],
  components: { MemberButton },
  computed: {
    contentImages() {
      let contentImages = this.getModuleConfig("intro_page.content_images") || []
      contentImages.sort((a, b) => a.order - b.order)
      return contentImages
    },
    showPage() {
      return this.getModuleConfig('intro_page.form_title')
        || this.getModuleConfig('intro_page.form_desc')
        || this.content
    },
    contentData() {
      return this.getModuleConfig("intro_page.content") ?? false;
    },
  },
};
</script>
